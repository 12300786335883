import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface AppState {
  isAuth: boolean;
  isAdmin: boolean;
  user: {
    jwt: string;
    username: string;
    email: string;
  };
  addAuth: (arg0: {
    jwt: string;
    username: string;
    email: string;
    isAdmin: boolean;
  }) => void;
  removeAuth: () => void;
}

const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      isAuth: false,
      isAdmin: false,
      user: {
        email: "",
        jwt: "",
        username: "",
      },
      addAuth: (payload) =>
        set(() => ({
          isAuth: true,
          isAdmin: payload.isAdmin,
          user: {
            email: payload.email,
            jwt: payload.jwt,
            username: payload.username,
          },
        })),
      removeAuth: () =>
        set((state) => ({
          isAuth: false,
          isAdmin: false,
          user: {
            email: "",
            jwt: "",
            username: "",
          },
        })),
    }),
    {
      name: "app-state",
      storage: createJSONStorage(() => localStorage),
      version: 0.02,
    }
  )
);

export { useAppStore };
