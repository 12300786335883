import React from "react";
import { Route, RouteProps } from "react-router-dom";

const Login = React.lazy(() => import("../../pages/auth/Login"));
const Language = React.lazy(() => import("../../cust/pages/Language/index"));
const ExamType = React.lazy(() => import("../../cust/pages/ExamType/index"));
const Questions = React.lazy(() => import("../../cust/pages/Questions/index"));
const Candidates = React.lazy(
  () => import("../../cust/pages/Candidates/index")
);
const AdminUser = React.lazy(() => import("../../cust/pages/AdminUser/index"));
const AssignExam = React.lazy(
  () => import("../../cust/pages/AssignExam/index")
);
const dashboard = React.lazy(() => import("../pages/dashboard"));
const ExamList = React.lazy(() => import("../pages/Exam/ExamList"));
const Exam = React.lazy(() => import("../pages/Exam/Exam"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

const CustPublicRoutes: RoutesProps[] = [
  {
    path: "/",
    name: "login",
    component: Login,
    route: Route,
    exact: true,
  },
];

const CustAuthRoutes: RoutesProps[] = [
  {
    path: "/admin",
    name: "langauge",
    component: dashboard,
    route: Route,
    exact: true,
  },
  {
    path: "/admin/langauge",
    name: "langauge",
    component: Language,
    route: Route,
    exact: true,
  },
  {
    path: "/admin/exam-type",
    name: "exam-type",
    component: ExamType,
    route: Route,
    exact: true,
  },
  {
    path: "/admin/exam-questions",
    name: "exam-questions",
    component: Questions,
    route: Route,
    exact: true,
  },
  {
    path: "/admin/exam-candidate",
    name: "exam-candidate",
    component: Candidates,
    route: Route,
    exact: true,
  },
  {
    path: "/admin/admin-user",
    name: "admin-user",
    component: AdminUser,
    route: Route,
    exact: true,
  },
  {
    path: "/admin/assign-exam",
    name: "assign-exam",
    component: AssignExam,
    route: Route,
    exact: true,
  },
];

const CustExamRoutes: RoutesProps[] = [
  {
    path: "/user",
    name: "langauge",
    component: ExamList,
    route: Route,
    exact: true,
  },
  {
    path: "/user/exam",
    name: "langauge",
    component: Exam,
    route: Route,
    exact: true,
  },
];

export { CustPublicRoutes, CustAuthRoutes, CustExamRoutes };
